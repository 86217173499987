<script setup>
import { SLabel } from '@shein-aidc/sui-label'
const EVOLU_SHEIN_TEXT = 'evoluSHEIN'
</script>

<template>
  <s-label
    v-bind="{ 'color': '#A86104', 'backgroundColor': '#FFF5E9' }"
  >
    {{ EVOLU_SHEIN_TEXT }}
  </s-label>
</template>
