<template>
  <div
    :class="['easy-coupon', { 'new-style': isNewStyle }]"
    :style="{
      height: height + 'px',
    }"
  >
    <template v-if="showLangText && langText">
      <span class="coupon-label">{{ langText }}</span>
      <span 
        class="divider"
      ></span>
    </template>
    <span class="coupon-code">{{ couponCode }}</span>
  </div>
</template>

<script>
export default {
  name: 'EasyCoupon',
  props: {
    langText: {
      type: String,
      default: '',
    },
    showLangText: {
      type: Boolean,
      default: true
    },
    couponCode: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 20,
    },
    isNewStyle: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang="less" scoped>
@sui_color_discount_dark: #FA6338;
.easy-coupon {
  max-width: 100%;
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: center;
  border:  1px solid @sui_color_discount_dark;
  border-radius: 1px;
  background: #FFF;
  overflow: hidden;
  mask-image: radial-gradient(circle at 0px 50%, transparent 3px, #fff 3px);
  height: initial;
  padding: 0px 3px 0 6px;
  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: @sui_color_discount_dark;
    border-radius: 50%;
    top: 50%;
    /* rtl:begin:ignore */
    margin-top: -4px;
    left: -5px;
  }
  font-size: 12px;
  color: @sui_color_discount_dark;
  .divider {
    width: 1px;
    height: 11px;
    background-color: @sui_color_discount_dark;
    margin: 0 3px;
    flex-shrink: 0;
  }
  .coupon-label {
    flex-shrink: 0;
  }
  .coupon-code {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.new-style {
  max-width: 234px;
  height: 20px;
  line-height: 20px;
  mask-image: none;
  border: none;
  border-radius: 2px;
  background: #FFF6F3;
  padding: 1px 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &::before {
    display: none;
  }
  .coupon-code {
    font-size: 12px;
    color: #C44A01;
  }
}
</style>
