<template>
  <div
    v-if="promotionTag.typeIdText"
    class="voucher_tag"
  >
    <div
      class="voucher-tag-content"
    >
      {{ promotionTag.typeIdText }}
      <SPopover
        v-if="!disableClick"
        v-model="showPopover"
        show-close-icon
        theme="dark"
        trigger="hover"
        :append-to-body="appendToBody"
        :prop-style="{
          width: '240px'
        }"
      >
        <template #reference>
          <sui_icon_info_12px_1 size="12px" />
        </template>
        <div class="voucher-popover-content">
          {{ language.SHEIN_KEY_PC_30875 }}
        </div>
      </SPopover>
      <sui_icon_info_12px_1
        v-else
        size="12px"
      />
    </div>

    <template v-if="promotionTag.subText">
      <div class="tag-split-line">
        |
      </div>
      <div class="voucher-subtext">
        {{ promotionTag.subText }}
      </div>
    </template>
  </div>
</template>
<script setup>
import { defineProps, defineExpose, computed, ref } from 'vue'
import { sui_icon_info_12px_1 } from '@shein-aidc/icon-vue3'
const props = defineProps({
  item: {
    type: Object,
    default() {
      return {}
    }
  },
  language: {
    type: Object,
    default() {
      return {}
    }
  },
  disableClick: {
    type: Boolean,
    default: false
  },
  appendToBody: {
    type: Boolean,
    default: true
  }
})
const showPopover = ref(false)
const promotionTag = computed(() => {
  return props.item.multiPromotionTags?.find(tag => tag?.typeId == 33) || {}
})

defineExpose({
  promotionTag
})
</script>
<style lang="less" scoped>
.voucher_tag {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #ffe6e6;
  border-radius: 2px;
  font-size: 12px;
  color: #ff3f3f;
  padding: 3px 4px;
  flex-wrap: nowrap;
  max-width: 100%;
  .voucher-subtext {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.voucher-tag-content {
  display: flex;
  align-items: center;
  gap: 2px;
  font-weight: 600;
}
.tag-split-line {
  opacity: 0.4;
  color: @sui_color_guide;
  font-size: 10px;
}
</style>

<style lang="less">
.voucher-popover-content {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}
</style>
