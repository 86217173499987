<script setup>
import { computed } from 'vue'
import { SLabel } from '@shein-aidc/sui-label'
import { Icon } from '@shein-aidc/icon-vue3'

const props = defineProps({
  cartQsTagInfo: {
    type: Object,
    default: () => ({})
  },
  // qs时效完整数据
  qsMallList: {
    type: Object,
    default: () => ({})
  },
  lastQsTime: {
    type: Boolean,
    default: false
  },
  // 商品唯一标识
  currentItemNo: {
    type: String,
    default: ''
  },
  // abtInfo: {
  //   type: Object,
  //   default: () => ({})
  // },
  // language: {
  //   type: Object,
  //   default: () => ({})
  // },
})

const IconStyle = computed(() => {
  return {
    size: quickShipTag.value?.themVarIcon?.size || '14px',
    color: quickShipTag.value?.themVarIcon?.color || '#198055'
  }
})

const quickShipTag = computed(() => {
  return props.cartQsTagInfo
})
const quickShipContent = computed(() => {
  let result = {}
  if(props.lastQsTime){
    // mall维度
    const { last_quickship_tag, last_tag_des } = props.qsMallList
    result = {
      tag: last_quickship_tag,
      tag_des: last_tag_des
    }
  } else {
    // 商品味维度
    const find = (props?.qsMallList?.qs_mall_list_all || []).find(f => f.item_no == props.currentItemNo)
    find && (result = {
      tag: find.product_quickship_tag,
      tag_des: find.product_quickship_des
    })
  }
  return result
})
</script>

<template>
  <s-label
    type="success"
    :class="[
      'quick-ship-tag',
      {
        'quick-ship-tag_new': quickShipTag?.isShowNewStyle,
        'quick-ship-tag_no_bg': quickShipTag.noLableStyle
      }
    ]"
    :style="quickShipTag && quickShipTag.labelStyle"
  >
    <!-- 前部分 -->
    <span class="quick-ship-tag__title">
      <Icon
        v-if="quickShipTag?.isShowNewStyle"
        name="sui_icon_qucikship_flat_14px"
        :size="IconStyle.size"
        :color="IconStyle.color"
        class="quick-ship-tag__icon"
      />
      <span
        :class="[
          'quick-ship-tag__text',
          {
            'quick-ship-tag__text_new': quickShipTag?.isShowNewStyle,
          }
        ]"
        :style="quickShipTag.themVarText"
      >
        {{ quickShipContent.tag }}
      </span>
    </span>

    <!-- 后部分 -->
    <template v-if="quickShipTag?.isShowNewStyle && !quickShipTag?.qsTimelinessInfo?.isLocationTop && quickShipContent.tag_des">
      <span class="divider"></span>
      <span class="quick-ship-tag__tip">&#x202A;{{ quickShipContent.tag_des }}</span>
    </template>
  </s-label>
</template>

<style lang="less" scoped>
.quick-ship-tag {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  &.quick-ship-tag_new {
    border-radius: 2px;
    background-color: #ECFCF3;
    box-shadow: 0 0 0 0.5px rgba(25, 128, 85, 0.20) inset;
    max-width: 100%;
  }
  &.quick-ship-tag_no_bg{
    color: #ffffff;
    background-color: transparent;
    box-shadow: none;
  }
  .quick-ship-tag__title {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    max-width: 100%;
    .quick-ship-tag__icon {
      margin: 0 2px 0 0;
    }
    .quick-ship-tag__text {
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .quick-ship-tag__text_new {
      padding: 0 1px 0 0;
      font-style: italic;
      font-weight: 590;
    }
  }
  .divider {
    flex-shrink: 0;
    display: inline-block;
    margin: 0 4px;
    width: 1px;
    height: 8px;
    background: rgba(25, 128, 85, 0.60);
  }
  .quick-ship-tag__tip {
    color: #198055;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    direction: rtl;
    unicode-bidi: embed;
  }
}
</style>
